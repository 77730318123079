import * as Sentry from '@sentry/svelte';
import type { HandleClientError, HandleFetch } from '@sveltejs/kit';
import { variables } from '$lib/variables';
import { v4 as uuidv4 } from 'uuid';

if (variables.STAGE !== 'LOCAL') {
  Sentry.init({
    dsn: variables.SVELTE_SENTRY_DSN,
    tunnel: variables.SENTRY_TUNNEL,
    replaysSessionSampleRate: 0.1,
    integrations: [new Sentry.Replay()],
    environment: variables.STAGE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });
}

export const handleError = (({ error, event }) => {
  const errorId = uuidv4();
  // example integration with https://sentry.io/
  if (variables.STAGE !== 'LOCAL') {
    Sentry.captureException(error);
  }
  return {
    message: 'Whoops!',
    errorId,
  };
}) satisfies HandleClientError;
